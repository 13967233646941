/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require("jquery");
    window._ = require("lodash");
    window.toastr = require("toastr/toastr");
    window.moment = require("moment/moment");
    window.NotifierServerSide = require("../js/NotifierServerSide");
    require("@fortawesome/fontawesome-free/js/all");
    // require('@fortawesome/fontawesome-free');
    require("jquery.uniform");
    require("bootstrap-sass/assets/javascripts/bootstrap");
    require("jquery-validation/dist/jquery.validate.min");

    require("daterangepicker/daterangepicker");

    require("bootstrap-select");

    require("datatables.net-bs/js/dataTables.bootstrap");
    require("datatables.net-buttons-bs/js/buttons.bootstrap");
    require("datatables.net-responsive-bs/js/responsive.bootstrap");
    require("datatables.net-rowgroup-bs/js/rowGroup.bootstrap");
    require("datatables.net-select-bs/js/select.bootstrap");
    require("datatables.net-fixedheader-bs/js/fixedHeader.bootstrap");
    require("datatables.net-rowreorder-bs/js/rowReorder.bootstrap");
    require("datatables.net-colreorder-bs/js/colReorder.bootstrap");
    require("datatables.net-scroller-bs/js/scroller.bootstrap");

    require("switchery/switchery");
    require("bootstrap-switch/dist/js/bootstrap-switch.min");

    require("bootstrap-select/js/bootstrap-select");
    require("select2/dist/js/select2");
    require("busy-load");

    require("../js/progressbar.min");

    require("../js/app2");
    require("../js/prism.min");

    //Jquery Mask
    require("../plugins/jquery-maskedinput/jquery.maskedinput.min");

    //Bootstrap Uploader
    require("../js/upload/fileinput/plugins/piexif.min.js");
    require("../js/upload/fileinput/plugins/purify.min.js");
    require("../js/upload/fileinput/plugins/sortable.min.js");
    require("../js/upload/fileinput/fileinput.min.js");

    //Velocity Animate
    require("../../node_modules/velocity-animate/velocity.min");

    //Fancy Box
    require("@fancyapps/fancybox/dist/jquery.fancybox.min");

    //Sanitize-html
    window.sanitizeHtml = require("sanitize-html/dist/sanitize-html");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

window.Ajax = require("../js/Ajax");

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
