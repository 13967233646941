class NotifierServerSide {

    static display(notifications) {
        if (Array.isArray(notifications)) {
            notifications.forEach(notification => {
                NotifierServerSide.displayNotification(notification);
            });
        }
    }

    static displayNotification(notification) {
        switch (notification.type) {
            case 'tip':
                notifier.tip(notification.message)
                break;
            case 'success':
                notifier.success(notification.message)
                break;
            case 'warning':
                notifier.warning(notification.message)
                break;
            case 'alert':
                notifier.alert(notification.message)
                break;
            case 'info':
            default:
                notifier.info(notification.message);
                break;
        }
    }

}

module.exports = NotifierServerSide;