class Ajax {

    static get(url, data) {
        return this._call(url, data, Ajax.GET);
    }

    static post(url, data) {
        return this._call(url, data, Ajax.POST);
    }

    static put(url, data) {
        return this._call(url, data, Ajax.PUT);
    }

    static patch(url, data) {
        return this._call(url, data, Ajax.PATCH);
    }

    static delete(url, data) {
        return this._call(url, data, Ajax.DELETE);
    }

    //This function is intended to make AJAX calls to resourceful routes a little bit simpler
    static byRouteName(route_name, data, id){
        data = data || {};
        let route_name_in_parts = route_name.split('.');
        if (route_name_in_parts.length !== 2)    return 'Error: route name should be in the form of "prefix.action"';

        let prefix = route_name_in_parts[0];
        let action = route_name_in_parts[1];
        let method, real_method, path, ajax_call = null;

        function getId(){if (id) return id; else throw new Error("ID is required for this route");};

        //Define the individual paths to use for each route
        if      (action ===  'store')       path = `${prefix}`;
        else if (action ===  'index')       path = `${prefix}`;
        else if (action ===  'create')      path = `${prefix}/create`;
        else if (action ===  'show')        path = `${prefix}/${getId()}`;
        else if (action ===  'update')      path = `${prefix}/${getId()}`;
        else if (action ===  'destroy')     path = `${prefix}/${getId()}`;
        else if (action ===  'edit')        path = `${prefix}/${getId()}/edit`;
        else                                path = `${prefix}`;

        //Define the routes by their corresponding method
        let actions_by_method = {
            "get":      ['index','create','show','edit'],
            "post":     ['store'],
            "patch":    ['update'],
            "delete":   ['destroy'],
        }

        //Extract that method into something a bit more usable
        method = (function() {
                for (var method_name in actions_by_method) {//Iterate through each property defined in the actions object
                    if (actions_by_method.hasOwnProperty(method_name)) {
                        if (actions_by_method[method_name].some(function (route) {return route === action})) {//Check the action (part of the route_name parameter) to see if it exists within this property
                            return method_name.toUpperCase();//If it was found, then return the name of this property
                        }
                    }
                }
                return "GET";//Otherwise, we'll assume the method should be GET -- seems the safest bet
            })();

        real_method = (method === 'GET') ? 'GET' : 'POST'; //Browsers seem to really only support GET/POST on forms
        if (real_method !== method){//If there's a discrepancy between method/real_method then we need to pass extra info along in our payload
            if(typeof data === "object" && data instanceof FormData) //If the payload is FormData, then we can append our extra info
                data.append('_method',method);
            else //Otherwise, let's assume that it's a data object that got passed along
                data['_method'] = method;
        }

        //Now invoke the proper Ajax request
        if (method === "GET")
            return this.get(path,data);
        else if (method === "POST")
            return this.post(path,data);
        else if (method === "PATCH")
            return this.patch(path,data);
        else if (method === "DELETE")
            return this.delete(path,data);

        return "405: Whoops - Is there a method for this madness?";
    }


    /**
     * @param {string} url
     * @param data
     * @param {string} method
     * @returns {Promise<any>}
     * @private
     *
     * Ajax wrapper to automatically handle errors such as a session expiration or csrf token expiration.
     * Sanitizes URLs adding the Web Root if needed
     * Forces the actual HTTP mehtod to either GET or POST. Adds _method property to the data if needed to tell Laravel actually requested method.
     *
     */
    static _call(url, data, method) {
        data = (data ? data : {});

        return new Promise((resolve, reject) => {

            let web_root = '/';

            if (!url.match(/^http/)) {  // If string doesn't start with http, concatenate web_root
                web_root = web_root.replace(/\/$/g, '') + '/'; // Ensures web_root has trailing slash
                url = url.replace(/^\/|\/$/g, '');  // Strips leading and trailing slashes
                url = `${web_root}${url}`;
            }

            let request = {
                method: 'get',
                url: url,
                data: null,
            };

            if (method === Ajax.GET) {
                if (data) {
                    request.url += (url.match(/\?/) !== null ? '&' : '?');
                    request.url += Ajax._objectToQueryParams(data);
                }
            } else {
                data._method = method;
                request.method = 'post';
                request.data = data;
            }

            axios(request)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    let response = null;

                    switch (error.response.status) {
                        case 301:
                        case 302:
                        case 307:
                            if(error.response.data && error.response.data.redirect_url && error.response.data.redirect_url) {
                                location.replace(error.response.data.redirect_url);
                            } else {
                                location.replace('/');
                            }
                            break;
                        case 401:   // Session Expired
                            window.notifier.alert('Your session has expired. Please refresh the page and try again.');
                            break;
                        case 419: // CSRF expired maybe? Looks like it
                            window.notifier.alert('The page has expired. Please refresh the page and try again.');
                            break;
                        case 404: // Not Found 
                        case 405: // Method not allowed
                        case 500: // Server error
                            window.notifier.alert('There was an error with your request. Please try again.');
                            break;
                        case 503: // Maintenance mode
                            window.notifier.alert('The system is currently undergoing maintenance. Please try again later.');
                            break;
                        default:
                            response = {
                                response: error.response,
                                message: error.message,
                            }
                    }

                    reject(response);
                });

        });

    }

    static _objectToQueryParams(obj) {
        let queryParams = [];
        _.forOwn(obj, (value, key) => {
            let newKey = encodeURIComponent(key);
            let newValue = encodeURIComponent(typeof value === 'object' ? JSON.stringify(value) : value);
            queryParams.push(`${key}=${newValue}`);
        })
        return queryParams.join('&');
    }

}

Ajax.GET = 'GET';
Ajax.POST = 'POST';
Ajax.PUT = 'PUT';
Ajax.PATCH = 'PATCH';
Ajax.DELETE = 'DELETE';

module.exports = Ajax;


