class CoreDataTablesFilter {

    static setFilters(datatable, column, filters, includeAll = true, activeColumn = null) {
        let html = '';

        if (includeAll) {
            let active = !activeColumn || activeColumn === 'all';
            let filter = new CoreDataTablesFilter('all', 'All', active);
            html += filter.render();
        }

        $.each(filters, function(idx, options) {
            let active = (!activeColumn && !includeAll) || activeColumn === options.filter;
            let filter = new CoreDataTablesFilter(options.filter, options.label, active);
            html += filter.render();
        });

        $("div.toolbar").html(`
            <div class="form-group">
                <ul class="nav nav-pills nav-pills-bordered dt-pill-style">
                    ${html}
                </ul>
            </div>
        `);

        $('.core-dt-filter').click(function(e) {
            e.preventDefault();
            let value = $(this).data('filter-value');
            if(value === 'all') {
                datatable.columns(column).search('', false).draw();
            } else {
                datatable.columns(column).search("^" + value + "$", true, false).draw();
            }
        });

        if(activeColumn && activeColumn !== 'all') {
            datatable.columns(column).search("^" + activeColumn + "$", true, false).draw();
        }
    }

    constructor(value, label = null, active = false) {
        this.value  = value;
        this.label  = label ? label : value;
        this.active = active ? 'active' : '';
    }

    render() {
        return `<li class="${this.active}">
                    <a class="core-dt-filter" data-filter-value="${this.value}" href="#bordered-pill${this.value.replace(/[^\w\s]/gi, '')}" data-toggle="tab" aria-expanded="false">${this.label}</a>
                </li>`;
    }

}

module.exports = CoreDataTablesFilter;