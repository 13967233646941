

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

$('[data-toggle="tooltip"]').tooltip();

// Awesome Notifier
let awnOptions = {
    position: 'top-right',
};

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

import AWN from "awesome-notifications"
window.notifier = new AWN(awnOptions);

require('../plugins/jquery-maskedinput/jquery.maskedinput.min');

$.fn.selectpicker.Constructor.BootstrapVersion = '3';

$( function() {
    $('form').show().css('visibility', 'visible');

    $('.selectpicker').selectpicker();

    $(".styled").uniform();

    $.busyLoadSetup({
        animation: "fade",
        background: "rgba(200, 152, 152, 0.86)",
        spinner: "circles"
    });
});

//Input Masks
//$('#date_of_birth').mask("99/99/9999");
//$('#phone').mask("(999) 999-9999");
//$("#postal_code").mask("a9a 9a9");


window.Vue = require('vue');
window.Typeahead = require('typeahead');
window.CoreDataTablesFilter = require('./CoreDataTablesFilter');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//Vue.component('example-component', require('./components/ExampleComponent.vue'));

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key)))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app'
// });
